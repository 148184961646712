<template>
  <hive-modal v-model="model" key-event>
    <icon-close
      circle
      :size="35"
      class="z-top fixed top-4 right-4 text-white clickable"
      @click="model = false"
    />
    <iframe class="full-screen" :src="url"></iframe>
  </hive-modal>
</template>

<script>
import IconClose from "../pepper/icons/IconClose";
import HiveModal from "@/pepper/components/HiveModal";
import { useVModel } from "@vueuse/core";
export default {
  name: "VrTour",
  components: { HiveModal, IconClose },
  props: { modelValue: Boolean, url: String },
  setup(props, { emit }) {
    const model = useVModel(props, "modelValue", emit);
    return { model };
  }
};
</script>

<style scoped>
.full-screen {
  @apply w-screen h-screen fixed top-0 left-0 overflow-hidden border-none m-0 box-border;
}
</style>
