<template>
  <div class="main-view">
    <div class="w-full relative">
      <hive-image
        :src="state.hero"
        class="hero-img cursor-pointer"
        @click="photoClick"
      />
      <div class="absolute bottom-2 flex justify-center w-full">
        <div
          class="p-2 opacity-60 hover:opacity-100 cursor-pointer text-xs flex-grow-0 border rounded-l-lg bg-white flex items-center space-x-1 uppercase"
          :class="{ 'rounded-r-lg': !state.showFloorPlan }"
          @click="photoClick"
        >
          <icon-image multiple outline :size="14" />
          <div>Photos</div>
          <div class="circle bg-black bg-opacity-20">
            {{ state.totalPhotos }}
          </div>
        </div>
        <div
          v-if="state.showFloorPlan"
          class="p-2 opacity-60 hover:opacity-90 cursor-pointer text-xs flex-grow-0 border rounded-r-lg border-l-0 bg-white  flex items-center space-x-1 uppercase"
        >
          <icon-floor-plan outline :size="14" />
          <div>Floor Plan</div>
        </div>
      </div>
    </div>
    <div class="title px-2 opacity-80">{{ state.label }}</div>
    <div class="opacity-50 flex items-center space-x-2.5 px-2">
      <icon-bed /><span class="text-sm mr-2">{{ state.bed }}</span>
      <icon-shower /><span class="text-sm mr-2">{{ state.bath }} </span>
      <icon-car /><span class="text-sm mr-2">{{ state.car }}</span>
    </div>

    <fluid-box class="pt-4 flex" padding="0.25rem">
      <fluid-item class="font-poppins px-2">
        <div class="mb-2 relative">
          <div
            v-if="state.vr"
            class="absolute top-0 right-0 clickable z-1"
            @click="vrClick"
          >
            <badge3d-tour />
          </div>
          <div v-if="state.price" class="font-bold opacity-50">
            {{ state.price }}
          </div>

          <div v-if="state.features" class="italic opacity-50">
            <ul>
              <li v-for="feature in state.features" :key="feature">
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
        <div class="sub-title font-bold pb-4">{{ state.title }}</div>
        <div v-html="state.body"></div>
      </fluid-item>

      <fluid-item md max="30%" min="280px">
        <card-reg class="w-full h-full opacity-80 " />
      </fluid-item>
    </fluid-box>
    <hr />
    <featured-videos />
    <card-reg-form />
  </div>
  <vr-tour v-model="state.vrOn" :url="state.vr" />
  <hive-photo-slider-full-screen
    v-model="state.sliderOn"
    :slides="state.photos"
    :base-url="state.photoFolder"
    key-event
  />
  <!--  <photo-slider-full-screen-->
  <!--    v-model="state.sliderOn"-->
  <!--    :slides="state.photos"-->
  <!--    :base-url="state.photoFolder"-->
  <!--  />-->
</template>

<script>
import HiveImage from "../pepper/components/HiveImage";
import { onMounted, reactive, watchEffect } from "vue";
import { findListing } from "../data/utils";
import CardReg from "../components/CardReg";
import FeaturedVideos from "@/components/FeaturedVideos";
import IconImage from "../pepper/icons/IconImage";
import IconFloorPlan from "../pepper/icons/IconFloorPlan";
import VrTour from "../components/VrTour";
import IconBed from "../pepper/icons/IconBed";
import IconCar from "../pepper/icons/IconCar";
import IconShower from "../pepper/icons/IconShower";
import Badge3dTour from "@/components/Badge3dTour";
import FluidBox from "@/pepper/layouts/FluidBox";
import FluidItem from "@/pepper/layouts/FluidItem";
import CardRegForm from "@/components/CardRegForm";
import HivePhotoSliderFullScreen from "@/pepper/components/HivePhotoSliderFullScreen";
import settings from "@/data/settings";
export default {
  name: "Residence",
  components: {
    HivePhotoSliderFullScreen,
    // PhotoSliderFullScreen,
    CardRegForm,
    FluidItem,
    FluidBox,
    Badge3dTour,
    IconShower,
    IconCar,
    IconBed,
    VrTour,
    IconFloorPlan,
    IconImage,
    FeaturedVideos,
    CardReg,
    HiveImage
  },
  props: {
    listingKey: { type: String, required: true }
  },
  setup(props) {
    const state = reactive({
      hero: null,
      totalPhotos: 0,
      photoFolder: "",
      photos: [],
      label: null,
      title: null,
      body: null,
      price: null,
      features: null,
      vr: undefined,
      vrOn: false,
      sliderOn: false,
      floorPlan: false,
      showFloorPlan: false
    });
    function vrClick() {
      state.vrOn = true;
    }
    function photoClick() {
      state.sliderOn = true;
    }

    watchEffect(() => {
      const listing = findListing(props.listingKey);
      // console.log(listing);
      state.hero = listing.photoFolder + listing.photos[0];
      state.totalPhotos = listing.photos.length;
      state.photoFolder = listing.photoFolder;
      state.photos = listing.photos;
      state.label = listing.label;
      state.title = listing.title;
      state.body = listing.body;
      state.price = listing.price;
      state.floorPlan = listing.floorPlan;
      if (listing.features) {
        const features = listing.features.trim().split("\n");
        for (let i = features.length - 1; i--; i >= 0) {
          let feature = features[i];
          if (feature.trim().length === 0) {
            features.splice(i, 1);
          }
        }
        state.features = features;
      }
      state.bed = listing.bed;
      state.car = listing.car;
      state.bath = listing.bath;
      state.vr = listing.vr;
      if (settings.showResidenceFloorPlansIfAvailable && listing.floorPlan) {
        state.showFloorPlan = true;
      } else {
        state.showFloorPlan = false;
      }
    });
    onMounted(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    });
    return { state, vrClick, photoClick };
  }
};
</script>

<style scoped>
::v-deep ul {
  list-style-type: circle;
  list-style-position: inside;
}
</style>
